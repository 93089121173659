<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}

.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}

.product_name {
	padding: 0.4rem 0.3rem;
	line-height: 0.3rem;
}

::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}

::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}

::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}

::v-deep .van-field__body input {
	color: $font_color_val;
}

::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

.add_user_btn {
	margin: 0.5rem 0.3rem 0.4rem;
	height: 0.9rem;
	border: 1px dashed #dddddd;
	display: flex;
	align-items: center;
	justify-content: center;

	.icon {
		width: 0.23rem;
		height: 0.22rem;
		background: url(../../../../assets/icon/add.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
	}

	.text {
		font-size: 0.3rem;
		color: $color_main;
		margin-left: 0.2rem;
	}
}

::v-deep .main_row {
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;

	.cell_title,
	.cell_value {
		color: $font_color_thd;
		font-size: 0.24rem;
	}
}

::v-deep .checkbox {
	margin: 0.5rem 0.3rem;
	align-items: flex-start;

	.van-checkbox__icon {
		position: relative;
		top: 0.06rem;
		border-radius: 0.06rem;

		.van-icon {
			border-radius: 0.06rem;
		}

		padding-right: 0.2rem;
	}

	.van-checkbox__label {
		font-size: 0.24rem;
		line-height: 0.36rem;
		color: $font_color_thd;
		margin-left: 0;
	}
}

.is_link {
	color: $color_main;
}

.user_info_cell {
	align-items: center;
}

.user_icon {
	width: 0.28rem;
	height: 0.28rem;
	background: url(../../../../assets/icon/delete.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	margin-right: 0.16rem;
}

.user_icon,
.user_title {
	font-size: 0.28rem;
}

::v-deep .must_read_pop {
	.van-action-sheet__content {
		overflow: hidden;
	}
}

.must_read_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 0 0.3rem 0.3rem;
	height: 70vh;
	box-sizing: border-box;

	.tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.5rem;

		.tab {
			display: flex;
			flex-direction: column;
			align-items: center;

			.text {
				font-size: 0.28rem;
				line-height: 0.36rem;
				color: $font_color_val;
			}

			.icon {
				width: 0.3rem;
				height: 0.06rem;
				margin-top: 0.16rem;
			}
		}

		.active {
			.text {
				color: $color_main;
			}

			.icon {
				background-color: $color_main;
			}
		}
	}

	.content {
		flex: 1;
		overflow-y: auto;
	}

	.loading_btn_box {
		width: 100%;
		padding: 0.2rem 0.3rem;
		box-sizing: border-box;
		background-color: #fff;

		.loading_btn {
			border-radius: 8px;
			height: 0.8rem;
		}
	}

	.content_info {
		word-break: break-all;
	}
}

.must_read_pop {
	z-index: 3001 !important;

	.loading_btn_content {
		display: flex;
		align-items: center;

		.time {
			color: #fff;
			margin-left: 0.4rem;
		}
	}
}

::v-deep .protect_user_title .text {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;

	.user_same {
		padding: 0 0.2rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		text-align: center;
		border-radius: 0.08rem;
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}

	.btns {
		display: flex;
		justify-content: space-between;

		.name_paste {
			margin-right: 0.2rem;
		}
	}
}

.per_rule {
	margin-left: 0.2rem;
}

// TODO:css
::v-deep .name_paste_dialog {
	z-index: 3002 !important;

	.name_textarea {
		textarea {
			max-height: 50vh;
			overflow-y: auto;
			// background-color: $line_color;
			border: 1px solid $line_color;
			padding: 0.08rem;
		}
	}

	.van-cell::after {
		border: none;
	}

	.btns {
		display: flex;
		justify-content: space-around;
		padding-bottom: 10px;
		padding: 0 0.3rem 10px;

		button {
			width: 42%;
		}
	}

	.van-dialog__cancel {
		color: #323233;
		background-color: #fff;
		background: #fff;
		border: 1px solid #ebedf0;
		border-radius: 2px;
		margin-right: 0.1rem;
	}

	.van-dialog__confirm {
		border-radius: 2px;
		margin-left: 0.1rem;
	}
}

::v-deep .van-overlay {
	z-index: 3001 !important;
}

::v-deep .analysis_toast {
	z-index: 3003 !important;
}

.error_name_dialog {
	.statistics {
		padding: 0.3rem;
		font-weight: bold;
		font-size: 14px;
	}

	.num {
		font-size: 20px;
	}

	.error_num {
		color: $danger;
	}

	.title {
		padding: 0 0.3rem;
		font-size: 14px;
		line-height: 14px;
		position: relative;
		top: 6px;
		z-index: 1;
	}

	.error {
		color: $danger;
		font-size: 12px;
		line-height: 14px;
		position: relative;
		top: -6px;
		padding: 0 0.3rem;
		margin-bottom: 0.2rem;
	}
}

.user_list_error_text {
	color: $danger;
}

.btn {
	box-sizing: border-box;
	position: absolute;
	right: 0px;
	margin-right: 0.4rem;
}

::v-deep {
	.van-picker__columns {
		.van-picker-column {
			&:nth-child(3) {
				display: none;
			}
		}
	}
}
</style>
<template>
	<div>
		<top-nav :bgColor="$store.state.cps_brindInfo.brandColor" @back="back">创建保单</top-nav>
		<div class="main_title product_name">{{ productInfo.name }}--{{ comboInfoWay }}</div>
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<!-- 投保信息 -->
			<active-title>选择投保信息</active-title>
			<van-cell-group :border="false">
				<van-field label="起保日期" :value="insForm.start" :border="false" readonly is-link clickable placeholder="请选择起保日期" @click="startDatePop = true" :rules="rules.start" />
				<van-field label="终保日期" disabled :value="insForm.end" :border="false" readonly is-link clickable placeholder="请选择终保日期" />
			</van-cell-group>
			<div class="line"></div>

			<!-- 投保人信息 -->
			<active-title>投保人信息</active-title>
			<van-cell-group :border="false">
				<van-field size="samll" label="投保人企业名称" v-model="insureForm.name" placeholder="请填写企业名称" :rules="rules.name" />
				<van-field size="samll" label="统一社会信用代码" v-model="insureForm.cardNo" placeholder="请填写统一社会信用代码" :rules="rules.cardNo" />
				<van-field label="联系人" v-model="insureForm.linkName" placeholder="请填写联系人姓名" :rules="rules.linkName" />
				<van-field label="联系人电话" v-model="insureForm.mobile" placeholder="请填写联系人电话" :rules="rules.mobile" />
				<van-field label="营业地址" v-model="insureForm.address" placeholder="请填写营业地址" :rules="rules.address" />
				<van-field label="邮箱" v-model="insureForm.email" placeholder="请填写邮箱" :rules="rules.email" />
			</van-cell-group>
			<div class="line"></div>
		</van-form>

		<active-title>被保人信息</active-title>
		<van-cell-group :border="false">
			<van-field disabled size="samll" label="被保人企业名称" v-model="insureForm.name" placeholder="请填写企业名称" :rules="rules.name" />
			<van-field disabled size="samll" label="统一社会信用代码" v-model="insureForm.cardNo" placeholder="请填写统一社会信用代码" :rules="rules.cardNo" />
			<van-field disabled label="联系人" v-model="insureForm.linkName" placeholder="请填写联系人姓名" :rules="rules.linkName" />
			<van-field disabled label="联系人电话" v-model="insureForm.mobile" placeholder="请填写联系人电话" :rules="rules.mobile" />
			<van-field disabled label="营业地址" v-model="insureForm.address" placeholder="请填写营业地址" :rules="rules.address" />
			<van-field disabled label="邮箱" v-model="insureForm.email" placeholder="请填写邮箱" :rules="rules.email" />
		</van-cell-group>

		<van-checkbox class="checkbox" v-model="rule_1" shape="square" icon-size="0.28rem" label-disabled>
			<span @click="rule_1 = !rule_1">本人已充分理解并同意</span>
			<span class="is_link" @click="seeRules">《保险条款》</span>
			<span class="is_link" @click="seeRule('投保声明')">《投保声明》</span>
			<br />
			<span class="is_link per_rule" @click="seeRule('个人信息保护政策')">《个人信息保护政策》</span>
		</van-checkbox>
		<van-checkbox class="checkbox" v-model="rule_2" shape="square" icon-size="0.28rem" @click="rule_2_check">
			本人已充分理解并同意
			<span class="is_link">《免责内容》</span>
			<span class="is_link">《被保险人同意声明》</span>
			<br />
			<span class="is_link per_rule">《投保提示》</span>
		</van-checkbox>
		<price-buy-btn v-show="buyBtnShow" :price="price" @buy="buy"></price-buy-btn>

		<!-- 弹出层 -->
		<!-- 起保日期 -->
		<van-calendar v-model="startDatePop" @confirm="startDateCheck" :min-date="startDateMinDate" color="#2594EF" :formatter="$base.calendarFormatter" />
		<bottom-info-pop v-model="infoPopShow" :title="infoPopTitle" :content="infoPopContent"></bottom-info-pop>
		<van-action-sheet v-model="mustReadPopShow" class="must_read_pop" title="请确认以下内容">
			<div class="must_read_content">
				<div class="tabs">
					<div class="tab" :class="{ active: mustReadIndex === 1 }">
						<span class="text">《免责内容》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 2 }">
						<span class="text">《被保险人同意声明》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 3 }">
						<span class="text">《投保提示》</span>
						<span class="icon"></span>
					</div>
				</div>
				<div class="content">
					<div class="content_info" v-show="mustReadIndex === 1" v-html="information.preventDuty">免责内容</div>
					<div class="content_info" v-show="mustReadIndex === 2" v-html="information.insuredDeclare">被保险人同意声明</div>
					<div class="content_info" v-show="mustReadIndex === 3" v-html="information.insuranceTip">投保提示</div>
				</div>
				<div class="loading_btn_box">
					<van-button class="loading_btn" @click="nextDoc" :disabled="isReading" type="info" color="#2594EF" block="" size="normal">
						<div class="loading_btn_content">
							<span v-show="mustReadIndex === 1">已阅读并同意《免责内容》</span>
							<span v-show="mustReadIndex === 2">已阅读并同意《被保险人同意声明》</span>
							<span v-show="mustReadIndex === 3">已阅读并同意《投保提示》</span>
							<van-count-down v-show="isReading" ref="countDown" class="time" :time="200" @finish="finish">
								<template #default="timeData">
									<span class="block">{{ timeData.seconds }}秒</span>
								</template>
							</van-count-down>
						</div>
					</van-button>
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>
<script>
import { Search, Area, Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Toast, Dialog, Cascader } from 'vant';
import { http_getServerTime } from '@/request/common';
import { productDictInfo, fixedInfo, getPrice, submitBill } from '@/request/api';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import moment from 'moment';

export default {
	name: 'cps_msDuty_insure',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
		[Area.name]: Area,
		[Search.name]: Search,
		[Picker.name]: Picker,
		[Cascader.name]: Cascader,
	},
	watch: {
		$route: {
			handler: function (route) {
				let params = route.params;
				if (params.hasOwnProperty('productInfo')) {
					this.productInfo = params.productInfo;
					this.information = params.information;
				}

				if (params.hasOwnProperty('product')) {
					this.product = params.product;
					this.setStartDate();
				}
				if (params.hasOwnProperty('otherInfo')) {
					this.otherInfo = params.otherInfo;
				}
				if (params.hasOwnProperty('choosePalinDict')) {
					this.choosePalinDict = params.choosePalinDict;
				}

				if (params.hasOwnProperty('userInfo')) {
					this.setUserInfo(params.userInfo);

					// 执行校验方法
					this.chekUserInfoList();

					// 执行重复检查
					this.validateRepeat();

					// 刷新DOM
					this.$forceUpdate();
				}

				if (params.detailTransNo) {
					this.detailTransNo = params.detailTransNo;
				}
			},
			immediate: true,
		},
	},
	created() {
		this.getProductInfo();
		this.getRules();
	},
	beforeRouteLeave(to, from, next) {
		if (to.name === 'cps_msDuty_index') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsProduct');
		}
		if (to.name === 'cpsSafeguardClause') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsProduct,cps_msDuty_insure');
		}

		if (to.name == 'cps_msDuty_index') {
			khs.stop();
		}
		this.scrollY = document.querySelector('html').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		if (from.name === 'cps_msDuty_index') {
			const store = from.matched[0].instances.default.$store;
			store.commit('set_cps_keepLiveCom', 'cpsHome,cpsProduct,cps_msDuty_insure');
		}
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	data() {
		return {
			// 投保信息
			insForm: {
				start: '',
				end: '',
			},

			// 投保人信息
			insureForm: {
				name: '',
				cardNo: '',
				linkName: '',
				mobile: '',
				address: '',
				email: '',
			},

			rules: {
				start: [{ required: true, trigger: 'blur', message: '请选择保障开始时间' }],
				name: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				cardNo: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				linkName: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				mobile: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
				address: [{ required: true, trigger: 'blur', message: '请填写营业地址' }],
				email: [{ required: false, validator: this.emailCheck, message: '请填写正确的邮箱' }],
			},

			scrollY: 0,
			information: {},
			productInfo: {},
			product: {},
			otherInfo: {}, //扩展字段
			choosePalinDict: {}, //价格ID,
			comboInfoWay: '', //计划方案
			timePop: false,
			insureArea: {
				name: '',
				site: '',
				address: '',
			},
			// 投保人信息
			userInfo: {
				userName: '',
				socialCode: '',
				phone: '',
			},
			// userList: [],//被保人列表
			proName: '', //产品名字
			// cityName: '', //城市名字
			// cityCode: '',//城市编码
			// countyName: '', //区县名字
			// 投保信息日历弹出层
			startDatePop: false,
			// endDatePop: false,
			// endDateMinDate: new Date(),
			// endDateMaxDate: new Date(),
			addressShow: false,
			timeList: [],
			timePop: false,
			// 条款
			rule_1: false,
			rule_2: false,
			// 投保声明、个人信息保护政策内容
			rule_1_list: [],
			infoPopShow: false,
			infoPopTitle: '',
			infoPopContent: '',

			mustReadPopShow: false,
			mustReadIndex: 1,
			isReading: true,

			buyBtnShow: true,
			price: 0,

			startDateMinDate: new Date(),
			startSecond: '00:00:00',
			isNextSecondStart: false, // 是否即时起保

			fieldValue: '',
			cascaderValue: '',
			options: [],
			cascaderShow: false,
			fieldNames: {
				text: 'name',
				value: 'value',
				children: 'children',
			},
			index: null,
			cityIndex: null,
		};
	},
	methods: {
		// // 刷新是跳转路由
		refresh() {
			if (this.$store.state.cps_createOrderRouteName == '') {
				this.$router.push({ name: 'cpsProduct', query: { code: this.$store.state.cps_code.code } });
			}
		},
		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},
		// 手机号码校验
		phoneCheck(val) {
			return regular.phone.test(val);
		},
		emailCheck(val) {
			if (!val) {
				return true;
			}
			return regular.email.test(val);
		},

		// 投保人证件号码校验方法
		cardNumCheck(val) {
			return Mtils.validation.isCreditCode(val);
		},
		back() {
			this.$store.commit('delete_keepAlivePage', 'cps_msDuty_insure');
			this.$router.push({ name: 'cps_msDuty_index', query: { productId: this.$store.state.cps_productId } });
		},

		// 获取产品信息
		getProductInfo() {
			productDictInfo(this.$store.state.cps_productId, this.$store.state.cps_plainId).then(res => {
				this.timeList = res.insureTimeList;
				this.comboInfoWay = res.comboInfo.comboName;
				this.price = res.comboInfo.viewPrice;
			});
		},
		setStartDate() {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				this.startDateMinDate = new Date();
			} else {
				this.startDateMinDate = new Date(Date.now() + 8.64e7);
			}
		},

		// 获取投保声明、个人信息保护政策
		getRules() {
			fixedInfo().then(res => {
				this.rule_1_list = res.fixedInfo;
			});
		},

		// 去保障条款页面
		seeRules() {
			this.$router.push({
				name: 'cpsSafeguardClause',
				params: {
					productId: this.productInfo.id,
				},
			});
		},

		seeRule(title) {
			this.infoPopTitle = title;
			this.rule_1_list.forEach(dict => {
				if (dict.typeDesc === title) {
					this.infoPopContent = dict.fixedInfo;
				}
			});
			this.infoPopShow = true;
		},
		nextDoc() {
			if (this.mustReadIndex < 3) {
				this.mustReadIndex++;
				this.isReading = true;
				this.$refs.countDown.reset();
			} else {
				this.rule_2 = true;
				this.mustReadPopShow = false;
			}
		},

		rule_2_check() {
			if (this.rule_2) {
				this.rule_2 = false;
				this.mustReadIndex = 1;
				this.isReading = true;
				this.mustReadPopShow = true;
				this.$nextTick(() => {
					this.$refs.countDown.reset();
				});
			}
		},

		finish() {
			this.isReading = false;
		},

		// 获取特殊标记字符串
		getSpecialStr() {
			let id = '';
			let userStr = localStorage.getItem('cps_brindInfo');
			if (userStr && userStr !== 'null' && userStr !== 'undefined') {
				let user = JSON.parse(userStr);
				if (user.userId) {
					id = user.userId;
				}
			}
			return `$CPSZRX-$MASHU-${id}-${Date.now()}`;
		},

		// 提交订单
		submitForm(timestamp = 0) {
			let specialString = this.getSpecialStr();
			let send = {
				assuredList: [
					{
						certificateContent: this.insureForm.cardNo,
						certificateType: '3',
						mobile: this.insureForm.mobile,
						name: this.insureForm.name,
						sex: '',
					},
				],
				extentionList: [
					{
						colName: 'equestrianAddress',
						colValue: this.insureForm.address,
					},
				],
				insBill: {
					// 投保信息
					enableDate: `${this.insForm.start} 00:00:00`,
					disEnableDate: `${this.insForm.end} 23:59:59`,
					id: '', // 编辑id
					insureType: '2',
					proId: this.productInfo.id,
					platform: 'CPS',
					remark: specialString,
				},
				insurer: {
					// 投保人信息
					insCredentials: this.insureForm.cardNo,
					insCredentialsType: '3',
					insEmail: this.insureForm.email,
					insName: this.insureForm.name,
					insPhone: this.insureForm.mobile,
					insSex: '',
					insType: '2',
				},

				optType: '2',
				productPriceVo: {
					comboId: this.$store.state.cps_plainId,
					proId: this.$store.state.cps_productId,
					priceId: this.choosePalinDict.productPriceList[0].priceId,
					insureTime: this.product.insureTime,
					insureTimeUnit: this.product.insureTimeUnit,

					viewTime: this.choosePalinDict.productPriceList[0].viewTime,
					eleView1: this.choosePalinDict.productPriceList[0].eleView1,
					viewAgeBelong: this.choosePalinDict.productPriceList[0].viewAgeBelong,
				},
			};
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交订单中',
			});
			return new Promise(resolve => {
				submitBill(send).then(
					res => {
						Toast.clear();
						Toast.success('订单提交成功');
						resolve(res.data);
					},
					err => {
						console.log(err);
					},
				);
			});
		},

		buy() {
			if (!this.rule_1 || !this.rule_2) {
				Toast('请先勾选条款');
				return false;
			}
			this.$refs.form.validate().then(
				() => {
					if (this.isNextSecondStart) {
						// 即时起保，从服务器获取时间戳,防止用户客户端时间不准
						Toast.loading({
							duration: 0, // 持续展示 toast
							forbidClick: true,
							message: '提交订单中',
						});
						http_getServerTime().then(resTP => {
							Toast.clear();
							this.submitForm(Number(resTP.timestamp)).then(res => {
								khs.finish(res.id);
								this.$store.commit('set_cps_billId', res.id);
								this.$router.push({
									name: 'cpsOrderPay',
									params: {
										isRedict: false,
									},
								});
							});
						});
					} else {
						this.submitForm().then(res => {
							khs.finish(res.id);
							this.$store.commit('set_cps_billId', res.id);
							this.$router.push({
								name: 'cpsOrderPay',
								params: {
									isRedict: false,
								},
							});
						});
					}
				},
				() => {
					Toast('请先按要求把信息填写完整');
				},
			);
		},

		// 时间转换
		gerDateTime(str) {
			if (!str) {
				return '';
			}
			let date = new Date(str);
			let year = date.getFullYear(),
				month = String(date.getMonth() + 1).padStart(2, '0'),
				day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		},

		// 起保日期选择
		startDateCheck(val) {
			this.insForm.start = this.gerDateTime(val);
			this.insForm.end = moment(val).add(1, 'y').subtract(1, 'd').format('YYYY-MM-DD');
			this.startDatePop = false;
		},
	},
	mounted() {
		this.refresh();
	},
};
</script>
